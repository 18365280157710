export class Pagination {
  per_page: number;
  current_page: number;
  total_count: number;

  constructor(obj: any) {
    this.per_page = obj?.per_page || 5;
    this.current_page = obj?.current_page || 1;
    this.total_count = obj?.total_count || 0;
  }

  toJSON() {
    return { ...this };
  }
}
